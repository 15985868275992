<template>
  <div>
    <div class="centered-text">
    <h1> Configuration of Video/audio sources </h1>
  </div>
  <div class="centered-text">
      <video id="screenShare" controls autoplay playsinline width="620px" height="400px"></video>
    </div>
      <div class="centered-text">
        <v-btn id="startButton"> Open source </v-btn>
      </div>
  </div>
</template>
<script>
  //import VideoPlayer from './videoPlayer.vue'
  import adapter from 'webrtc-adapter'

  export default {
    data (){
      return{
        stream: '',
        configuration: { iceServers:[ 'stun:stun1.l.google.com:19302' ]}
			}
		},
    mounted() {
      if (adapter.browserDetails.browser == 'firefox') {
  adapter.browserShim.shimGetDisplayMedia(window, 'screen');
}

function init() {
  document.querySelector('#startButton').addEventListener('click', startButton);
}


function handleSuccess(stream) {
  startButton.disabled = true;
  const video = document.querySelector('video');
  video.srcObject = stream;

  // demonstrates how to detect that the user has stopped
  // sharing the screen via the browser UI.
  stream.getVideoTracks()[0].addEventListener('ended', () => {
    errorMsg('The user has ended sharing the screen');
    startButton.disabled = false;
  });
}

function handleError(error) {
  errorMsg(`getDisplayMedia error: ${error.name}`, error);
}

function errorMsg(msg, error) {
  const errorElement = document.querySelector('#errorMsg');
  errorElement.innerHTML += `<p>${msg}</p>`;
  if (typeof error !== 'undefined') {
    this.push.error(error);
  }
}

const startButton = document.getElementById('startButton');
startButton.addEventListener('click', () => {
  navigator.mediaDevices.getDisplayMedia({video: true})
      .then(handleSuccess, handleError);
});

if ((navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices)) {
  startButton.disabled = false;
} else {
  errorMsg('getDisplayMedia is not supported');
}
  init();


      },
    }
</script>
<style scss>
  .centered-text
  {
    text-align: center;
    position: center;
    color: gold;
  }
</style>
